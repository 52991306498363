<template>
  <div>
    <v-container fluid>

      <v-row>
        <v-col cols="12">
          <v-card class="shadow rounded-lg">
            <v-card-text class="pa-8 text-lg-start text-center">
              <v-row align="center">
                <v-col>

                  <h1 class="font-weight-medium py-4 grey--text text--darken-3">
                    Welcome back !
                  </h1>

                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse pellentesque sit amet turpis
                    quis consectetur.
                  </p>

                </v-col>

                <v-col class="text-lg-end text-center">
                  <img :src="require('@/assets/eagle.svg')" alt="" width="250"/>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!--      <v-row>-->
      <!--        <v-col cols="3">-->
      <!--          <v-card class="shadow rounded-lg">-->
      <!--            <v-card-text class="pa-6">-->
      <!--              <div class="d-flex align-center justify-space-between">-->
      <!--                <div>-->
      <!--                  <span class="font-weight-medium d-block grey&#45;&#45;text text&#45;&#45;darken-3 fs-20"> 78 +</span>-->
      <!--                  <p class="mt-1 mb-0">Campagnes</p>-->
      <!--                </div>-->
      <!--                <v-spacer/>-->
      <!--                <v-avatar class="bg-grad-primary" size="60">-->
      <!--                  <v-icon color="primary" size="30">mdi-file-document-outline</v-icon>-->
      <!--                </v-avatar>-->
      <!--              </div>-->
      <!--            </v-card-text>-->
      <!--          </v-card>-->
      <!--        </v-col>-->
      <!--        <v-col cols="3">-->
      <!--          <v-card class="shadow rounded-lg">-->
      <!--            <v-card-text class="pa-6">-->
      <!--              <div class="d-flex align-center justify-space-between">-->
      <!--                <div>-->
      <!--                  <span class="font-weight-medium d-block grey&#45;&#45;text text&#45;&#45;darken-3 fs-20"> 2500 +</span>-->
      <!--                  <p class="mt-1 mb-0">Base de données</p>-->
      <!--                </div>-->
      <!--                <v-spacer/>-->
      <!--                <v-avatar class="bg-grad-orange" size="60">-->
      <!--                  <v-icon color="orange" size="30">mdi-database-outline</v-icon>-->
      <!--                </v-avatar>-->
      <!--              </div>-->
      <!--            </v-card-text>-->
      <!--          </v-card>-->
      <!--        </v-col>-->
      <!--        <v-col cols="3">-->
      <!--          <v-card class="shadow rounded-lg">-->
      <!--            <v-card-text class="pa-6">-->
      <!--              <div class="d-flex align-center justify-space-between">-->
      <!--                <div>-->
      <!--                  <span class="font-weight-medium d-block grey&#45;&#45;text text&#45;&#45;darken-3 fs-20"> 12 +</span>-->
      <!--                  <p class="mt-1 mb-0">Agences</p>-->
      <!--                </div>-->
      <!--                <v-spacer/>-->
      <!--                <v-avatar class="bg-grad-blue" size="60">-->
      <!--                  <v-icon color="blue" size="30">mdi-office-building-marker</v-icon>-->
      <!--                </v-avatar>-->
      <!--              </div>-->
      <!--            </v-card-text>-->
      <!--          </v-card>-->
      <!--        </v-col>-->
      <!--        <v-col cols="3">-->
      <!--          <v-card class="shadow rounded-lg">-->
      <!--            <v-card-text class="pa-6">-->
      <!--              <div class="d-flex align-center justify-space-between">-->
      <!--                <div>-->
      <!--                  <span class="font-weight-medium d-block grey&#45;&#45;text text&#45;&#45;darken-3 fs-20"> 19 +</span>-->
      <!--                  <p class="mt-1 mb-0">Cadeaux</p>-->
      <!--                </div>-->
      <!--                <v-spacer/>-->
      <!--                <v-avatar class="bg-grad-secondary" size="60">-->
      <!--                  <v-icon color="secondary" size="30">mdi-gift-outline</v-icon>-->
      <!--                </v-avatar>-->
      <!--              </div>-->
      <!--            </v-card-text>-->
      <!--          </v-card>-->
      <!--        </v-col>-->
      <!--      </v-row>-->

      <!--      <v-row>-->
      <!--        <v-col class="d-flex flex-column" cols="6">-->
      <!--          <v-card class="shadow rounded-lg flex d-flex flex-column">-->
      <!--            <v-card-title>-->
      <!--              Campagne par agence-->
      <!--            </v-card-title>-->
      <!--            <v-card-subtitle>-->
      <!--              Lorem ipsum dolor sit amet, consectetur-->
      <!--            </v-card-subtitle>-->
      <!--            <v-card-text class="flex">-->
      <!--              <chart1/>-->
      <!--            </v-card-text>-->
      <!--          </v-card>-->
      <!--        </v-col>-->
      <!--        <v-col class="d-flex flex-column" cols="6">-->
      <!--          <v-card class="shadow rounded-lg flex d-flex flex-column">-->
      <!--            <v-card-title>-->
      <!--              Type pos-->
      <!--            </v-card-title>-->
      <!--            <v-card-subtitle>-->
      <!--              Aenean vestibulum arcu nec pellentesque dictum-->
      <!--            </v-card-subtitle>-->
      <!--            <v-card-text class="flex">-->
      <!--              <chart2/>-->
      <!--            </v-card-text>-->
      <!--          </v-card>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
    </v-container>
  </div>
</template>

<script>
// import Chart1 from "@/views/dashboard/chart/chart1.vue";
// import Chart2 from "@/views/dashboard/chart/chart2.vue";

export default {
  // components: {Chart2, Chart1}

}
</script>

<style scoped>

</style>